.customSpinner
  display: flex
  flex-direction: column
  height: 80vh
  position: absolute
  top: 0
  left: 0
  right: 0
  justify-content: center
  align-items: center

  \:global(.spinner-border)
    color: #dc3545
    width: 5rem
    height: 5rem

.absoluteSpinner
  composes: customSpinner
  z-index: 100000

.relativeSpinner
  composes: customSpinner
  position: relative !important
  height: auto !important
